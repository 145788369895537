/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from "react";
import Badge from "../assets/Group 268.svg";
import AddGreen from "../assets/add.svg";
import AddGrey from "../assets/add-grey.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CreateClan from "./modals/CreateClan";
import axios from "../axios";
import JoinCompetition from "./modals/JoinCompetition";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player";

function Home() {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [joinOpen, setJoinOpen] = useState(false);
  const [acc, setAcc] = React.useState([]);
  // const [accInCompetition, setAccInCompetiton] = useState()
  const [currentCompetiton, setCurrentCompetition] = useState({});
  const [dDay, setDDay] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [startEnd, setStartEnd] = useState("");
  const [clanIdAvailable, setClanIdAvailable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [checkAcctCurrentComp, setCheckAcctCurrentComp] = useState([]);
  const [canCreate, setCanCreate] = useState({
    can: false,
    reason: "",
  });
  const [inComp, setInComp] = useState(false);
  const [inCompinactive, setInCompInactive] = useState(false);
  const [play, setPlay] = useState(false);
  const params = useMemo(() => new URLSearchParams(window.location.search), []);

  // console.log(window.innerWidth);
  // console.log(checkAcctCurrentComp)

  // console.log(compEndDate)

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  React.useEffect(() => {
    var token = searchParams.get("token");
    var refresh = searchParams.get("refresh_token");
    if (token) {
      localStorage.setItem("access_token", token);
    }

    if (refresh) {
      localStorage.setItem("refresh_token", refresh);
    }

    if (token !== null) {
      //console.log("token is not null");
      return navigate("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    // axios.get("api/getaccdetails/").then((res) => {
    //   console.log(res)
    //   setAcc(res.data.filter(acc => acc.acc.typeofaccount.toLowerCase() === "zuma" && acc.blown === false && acc.acc.number !== 0 && acc.acc.has_aso === null && acc.acc.has_sca === false));
    // });
    // axios.get("leaderboard/check/").then((res) => {
    //   console.log(res)

    // });
    axios.get("leaderboard/currentcompetition/").then((res) => {
      console.log(res);
      setCurrentCompetition(res.data);
      localStorage.setItem("comp_id", res.data.id);
    });
    axios.get("/leaderboard/can-create-join/").then((res) => {
      console.log(res);
      if (res.data.clan_id && res.data.clan_id !== null) {
        setClanIdAvailable(true);
      } else {
        setClanIdAvailable(false);
      }
      // setAcc(res.data.filter(acc => acc.acc.number !== 0));
    });
    axios
      .get("/leaderboard/can-create-join/")
      .then((res) => {
        console.log(res);
        setCanCreate({ can: res.data.status, reason: res.data.reason });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dDay, refresh]);

  React.useEffect(() => {
    const currentDate = new Date();
    const providedDate = new Date(currentCompetiton?.start_date);
    let target;
    if (providedDate < currentDate) {
      // console.log("The provided date is behind the current date.");
      setStartEnd("End");
      target = new Date(currentCompetiton?.end_date);
    } else {
      // console.log("The provided date is ahead of the current date.");
      setStartEnd("Start");
      target = new Date(currentCompetiton?.start_date);
    }

    const interval = setInterval(() => {
      const now = new Date();
      const difference = target.getTime() - now.getTime();

      const d = Math.floor(difference / (1000 * 60 * 60 * 24));
      setDays(d);

      const h = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      setHours(h);

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(m);

      const s = Math.floor((difference % (1000 * 60)) / 1000);
      setSeconds(s);

      if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
        setDDay(!dDay);
        setDays(0);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        setStartEnd("");
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [currentCompetiton?.end_date, currentCompetiton?.start_date, dDay]);

  React.useEffect(() => {
    async function fetchDataForObject(object) {
      try {
        axios
          .get(`leaderboard/check/`)
          .then((res) => {
            console.log(res);
            if (
              res.data.competition === "False" &&
              res.data.status === "inactive"
            ) {
              setInComp(false);
            } else if (
              res.data.competition === "True" &&
              res.data.status === "inactive"
            ) {
              setInComp(true);
              setInCompInactive(true);
            } else {
              setInComp(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.error(
          `Error fetching data for object with id ${object.id}:`,
          error
        );
      }
    }
    // if (acc.length > 0) {
    //   for (let i = 0; i < acc.length; i++) {
    //     fetchDataForObject(acc[i])
    //   }
    // }

    fetchDataForObject();
  }, [acc, dDay, refresh]);

  return (
    <div className="lg:px-[5rem] px-[1rem] pb-[3.56rem]">
      <ToastContainer />
      <div className="pt-[1.87rem]">
        <div className="bg-[#66CC7C] flex flex-col xl:flex-row items-center justify-between gap-[20px] rounded-[1.25rem] 2xl:pr-[3.88rem] xl:pr-[2rem] 2xl:pl-[2.5rem] xl:pl-[1rem] py-[2.75rem]">
          <p className="font-plus text-center lg:text-[1.5rem] text-[1.125rem] font-bold text-white">
            {dDay || currentCompetiton?.status === "closed"
              ? "Competition has ended"
              : startEnd === "Start"
              ? "Countdown to Start of Competition"
              : "Countdown to End of Competition"}
          </p>

          {currentCompetiton?.status !== "closed" && (
            <div className="flex items-center mt-[0.88rem] lg:mt-0">
              <div className="flex flex-col items-center">
                <p className="w-[2.375rem] h-[1.875rem] bg-white border border-white shadow-[1px_1px_0px_0px_rgba(21,21,21,1)] font-plus font-bold text-[0.875rem] text-[#151515] flex items-center justify-center">
                  {days}
                </p>
                <p className="font-plus font-semibold text-white text-[0.75rem]">
                  Days
                </p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="16"
                viewBox="0 0 7 16"
                fill="none"
                className="mx-[0.62rem]"
              >
                <circle cx="3.45946" cy="3.45946" r="3.45946" fill="#EAEAEA" />
                <circle cx="3.45946" cy="12.5405" r="3.45946" fill="#EAEAEA" />
              </svg>
              <div className="flex flex-col items-center">
                <p className="w-[2.375rem] h-[1.875rem] bg-white border border-white shadow-[1px_1px_0px_0px_rgba(21,21,21,1)] font-plus font-bold text-[0.875rem] text-[#151515] flex items-center justify-center">
                  {hours}
                </p>
                <p className="font-plus font-semibold text-white text-[0.75rem]">
                  Hours
                </p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="16"
                viewBox="0 0 7 16"
                fill="none"
                className="mx-[0.62rem]"
              >
                <circle cx="3.45946" cy="3.45946" r="3.45946" fill="#EAEAEA" />
                <circle cx="3.45946" cy="12.5405" r="3.45946" fill="#EAEAEA" />
              </svg>
              <div className="flex flex-col items-center">
                <p className="w-[2.375rem] h-[1.875rem] bg-white border border-white shadow-[1px_1px_0px_0px_rgba(21,21,21,1)] font-plus font-bold text-[0.875rem] text-[#151515] flex items-center justify-center">
                  {minutes}
                </p>
                <p className="font-plus font-semibold text-white text-[0.75rem]">
                  Minutes
                </p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="16"
                viewBox="0 0 7 16"
                fill="none"
                className="mx-[0.62rem]"
              >
                <circle cx="3.45946" cy="3.45946" r="3.45946" fill="#EAEAEA" />
                <circle cx="3.45946" cy="12.5405" r="3.45946" fill="#EAEAEA" />
              </svg>
              <div className="flex flex-col items-center">
                <p className="w-[2.375rem] h-[1.875rem] bg-white border border-white shadow-[1px_1px_0px_0px_rgba(21,21,21,1)] font-plus font-bold text-[0.875rem] text-[#151515] flex items-center justify-center">
                  {seconds}
                </p>
                <p className="font-plus font-semibold text-white text-[0.75rem]">
                  Seconds
                </p>
              </div>
            </div>
          )}
          {!params.get("trader_email") &&
            currentCompetiton?.status !== "closed" && (
              <a
                className="text-white underline mt-5 lg:mt-0"
                target="blank"
                href={`https://gohamma.com/home?token=${localStorage.getItem(
                  "access_token"
                )}&refresh_token=${localStorage.getItem("refresh_token")}`}
              >
                <button
                  className={`${
                    inComp ? "bg-[#D62C26] text-white" : "bg-white text-black"
                  } font-bold rounded-[10px] py-[20px] 2xl:px-[51px] xl:px-[25px] px-[10px]`}
                >
                  {inComp ? "Leave Competition" : "Join Competition"}
                </button>
              </a>
            )}
        </div>

        <div className="flex flex-col lg:flex-row items-center gap-[2.87rem] mt-[2.5rem]">
          {!clanIdAvailable && canCreate.can === true && (
            <button
              onClick={() => setOpen(!open)}
              className="flex flex-col items-center bg-white border-[0.5px] border-[#808080] w-[21.5rem] pt-[1.88rem] pb-[2.66rem] rounded-[1.25rem]"
            >
              <div className="flex items-center gap-[0.31rem] mb-[0.63rem]">
                <img src={Badge} alt="" />
                <p className="font-plus text-[1.125rem] font-semibold text-[#151515]">
                  Create a Clan
                </p>
              </div>
              <img src={AddGreen} alt="" />
            </button>
          )}
          {!clanIdAvailable && canCreate.can === true && (
            <Link to="/?clan">
              <button className="flex flex-col items-center bg-white border-[0.5px] border-[#808080] w-[21.5rem] pt-[1.88rem] pb-[2.66rem] rounded-[1.25rem]">
                <p className="font-plus text-[1.125rem] font-semibold text-[#151515] mb-[1rem]">
                  Join a Clan
                </p>
                <img src={AddGrey} alt="" />
              </button>
            </Link>
          )}
        </div>

        <div className="mt-[1.88rem]">
          <h1 className="font-plus text-[1.25rem] font-bold text-[#151515] mb-[1.25rem]">
            Get Started
          </h1>
          <div className="flex flex-col lg:flex-row gap-[1.87rem]">
            <div
              className={`relative  flex items-center justify-center lg:w-[40%] w-[100%] lg:h-[21.0525rem] h-[14.875rem] bg-black rounded-[20px]`}
              onClick={() => {
                if (play) {
                  setPlay(false);
                } else {
                  setPlay(true);
                }
              }}
            >
              {!play && (
                <svg
                  className="absolute cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="70"
                  height="70"
                  viewBox="0 0 70 70"
                  fill="none"
                >
                  <path
                    d="M55.6209 57.8081C55.0667 57.8081 54.5125 57.6039 54.075 57.1664C53.2292 56.3206 53.2292 54.9206 54.075 54.0748C64.6042 43.5456 64.6042 26.4248 54.075 15.9248C53.2292 15.0789 53.2292 13.6789 54.075 12.8331C54.9209 11.9873 56.3209 11.9873 57.1667 12.8331C69.3875 25.0539 69.3875 44.9456 57.1667 57.1664C56.7292 57.6039 56.175 57.8081 55.6209 57.8081Z"
                    fill="white"
                  />
                  <path
                    d="M14.3792 57.8081C13.825 57.8081 13.2708 57.6039 12.8333 57.1664C0.612516 44.9456 0.612516 25.0539 12.8333 12.8331C13.6792 11.9873 15.0792 11.9873 15.925 12.8331C16.7708 13.6789 16.7708 15.0789 15.925 15.9248C5.39585 26.4539 5.39585 43.5748 15.925 54.0748C16.7708 54.9206 16.7708 56.3206 15.925 57.1664C15.4875 57.6039 14.9333 57.8081 14.3792 57.8081Z"
                    fill="white"
                  />
                  <path
                    d="M35 66.2377C31.3542 66.2085 27.8833 65.6252 24.6458 64.4877C23.5083 64.0794 22.8958 62.8252 23.3042 61.6877C23.7125 60.5502 24.9375 59.9377 26.1042 60.346C28.9042 61.3085 31.8792 61.8335 35.0292 61.8335C38.15 61.8335 41.1542 61.3085 43.925 60.346C45.0625 59.9669 46.3167 60.5502 46.725 61.6877C47.1333 62.8252 46.5208 64.0794 45.3833 64.4877C42.1167 65.6252 38.6458 66.2377 35 66.2377Z"
                    fill="white"
                  />
                  <path
                    d="M44.625 9.74186C44.3917 9.74186 44.1292 9.7127 43.8958 9.6252C41.125 8.63353 38.1208 8.1377 35 8.1377C31.8792 8.1377 28.9042 8.6627 26.1042 9.6252C24.9375 10.0044 23.7125 9.42103 23.3042 8.28353C22.8958 7.14603 23.5083 5.89186 24.6458 5.48353C27.9125 4.34603 31.3833 3.7627 35 3.7627C38.6167 3.7627 42.1167 4.34603 45.3542 5.48353C46.4917 5.89186 47.1042 7.14603 46.6958 8.28353C46.375 9.1877 45.5292 9.74186 44.625 9.74186Z"
                    fill="white"
                  />
                  <path
                    d="M25.4917 34.9998V30.1289C25.4917 24.0623 29.7792 21.5831 35.0292 24.6164L39.2584 27.0664L43.4875 29.5164C48.7375 32.5498 48.7375 37.5081 43.4875 40.5414L39.2584 42.9914L35.0292 45.4414C29.7792 48.4748 25.4917 45.9956 25.4917 39.9289V34.9998Z"
                    fill="white"
                  />
                </svg>
              )}
              <ReactPlayer
                config={{
                  file: {
                    attributes: {
                      poster:
                        "https://res.cloudinary.com/ddlupbcws/image/upload/v1712578833/Copy_of_Copy_of_Thumbnails_bzqrk1.webp",
                    },
                  },
                }}
                url="https://res.cloudinary.com/ddlupbcws/video/upload/v1711808224/Competition_High_Quality_p2tfsd.webm"
                playing={play}
                width={"100%"}
                height={"100%"}
                onEnded={() => setPlay(false)}
              />
            </div>
            <div
              className={`relative flex items-center justify-center lg:w-[60%] w-[100%] lg:min-h-[21.0625rem] h-[14.875rem] rounded-[10px]`}
            >
              <img
                src="https://res.cloudinary.com/ddlupbcws/image/upload/v1711621388/Phone_background_1_pggowq.webp"
                alt=""
                className="h-[100%] w-[100%] rounded-[20px] blur-[3px]"
              />
              <div className="absolute w-[100%] h-[100%] flex flex-col lg:flex-row justify-between">
                <div className="lg:pl-[40px] lg:pt-[61px] pt-[30px] flex flex-col items-center lg:items-start lg:w-[50%]">
                  <svg
                    className="lg:mb-[30px] mb-[15px]"
                    width="188"
                    height="42"
                    viewBox="0 0 188 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M173.008 12.8801H175.507V42H173.008V12.8801Z"
                      fill="white"
                    />
                    <path
                      d="M160.515 1.39999C160.515 0.626796 161.13 0 161.889 0H186.626C187.385 0 188 0.626798 188 1.39999V14.5599C188 15.3331 187.385 15.9599 186.626 15.9599H161.889C161.13 15.9599 160.515 15.3331 160.515 14.5599V1.39999Z"
                      fill="#19F256"
                    />
                    <path
                      d="M41.4463 34.2455H42.0806V41.9993H41.4463V34.2455Z"
                      fill="white"
                    />
                    <path
                      d="M39.5414 33.5998H44.1927V36.5463H39.5414V33.5998Z"
                      fill="#19F256"
                    />
                    <path
                      d="M15.007 1.67999H23.8298V41.9998H15.007V26.2919H8.82281V41.9998H0V1.67999H8.82281V17.3879H15.007V1.67999Z"
                      fill="white"
                    />
                    <path
                      d="M45.7715 1.67999L57.1505 41.9998H47.9978L41.3189 18.4799L34.6399 41.9998H25.4873L36.9487 1.67999H45.7715Z"
                      fill="white"
                    />
                    <path
                      d="M80.798 1.67999H89.5383V41.9998H80.7155V21.8399L76.098 32.5078H72.2225L67.605 21.8399V41.9998H58.7822V1.67999H67.605L74.2015 16.7999L80.798 1.67999Z"
                      fill="white"
                    />
                    <path
                      d="M114.859 1.67999H123.6V41.9998H114.777V21.8399L110.159 32.5078H106.284L101.666 21.8399V41.9998H92.8437V1.67999H101.666L108.263 16.7999L114.859 1.67999Z"
                      fill="white"
                    />
                    <path
                      d="M145.54 1.67999L156.919 41.9998H147.767L141.088 18.4799L134.409 41.9998H125.256L136.717 1.67999H145.54Z"
                      fill="white"
                    />
                  </svg>

                  <p className="2xl:mb-[74px] xl:mb-[44px] lg:mb-[24px] text-[#EBEBEB] text-center lg:text-start">
                    Visit the Markets section of Hamma trading platform to start
                    trading now.
                  </p>

                  {!params.get("trader_email") && (
                    <a
                      className="text-white underline mt-5 lg:mt-0"
                      target="blank"
                      href={`https://gohamma.com/?token=${localStorage.getItem(
                        "access_token"
                      )}&refresh_token=${localStorage.getItem(
                        "refresh_token"
                      )}`}
                    >
                      <button className="bg-[#FFFFFF33] px-[23px] py-[19px] rounded-[5px]">
                        Visit Dashboard
                      </button>
                    </a>
                  )}
                </div>
                <div className="lg:w-[371px] lg:h-[208px] hidden lg:block mt-auto mb-[7px]">
                  <img
                    src="https://res.cloudinary.com/ddlupbcws/image/upload/v1711628762/New_Macbook_Pro_Mockup_Front_View_1_1_t6kuif.webp"
                    alt=""
                    className="h-[100%] w-[100%] rounded-[10px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[1.88rem]">
          <h1 className="font-plus text-[1.25rem] font-bold text-[#151515] mb-[1.25rem]">
            Getting To Know You
          </h1>
          <div className="flex flex-col lg:flex-row gap-[1.87rem]">
            <div
              className={`relative flex items-center justify-center lg:w-[60%] w-[100%] lg:h-[21.0625rem] h-[14.875rem] rounded-[20px]`}
            >
              <img
                src="https://res.cloudinary.com/ddlupbcws/image/upload/v1711632605/collage-finance-banner-concept_1_oukq8s.webp"
                alt=""
                className="h-[100%] w-[100%] rounded-[20px] blur-[3px]"
              />
              <div className="absolute w-[100%] h-[100%] lg:pt-[70px] pt-[15px] lg:pl-[40px] flex flex-col items-center lg:items-start">
                <h1 className="text-white font-plus text-[1.9rem] font-extrabold lg:mb-[24px] mb-[12px] lg:w-[250px] text-center lg:text-start">
                  Favorite Trading Strategy
                </h1>
                <p className="text-white font-plus font-medium text-[0.875rem] lg:mb-[38px] mb-[19px]">
                  Vote for your favorite Trading Strategy
                </p>
                {!params.get("trader_email") && (
                  <a
                    href="https://survey.zohopublic.com/zs/zRDHeJ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="bg-[#00C500] font-semibold px-[30px] py-[17px] rounded-[5px] text-white">
                      Vote here
                    </button>
                  </a>
                )}
              </div>
            </div>
            <div
              className={`relative flex items-center justify-center lg:w-[40%] w-[100%] lg:h-[21.0625rem] h-[14.875rem] rounded-[20px]`}
            >
              <img
                src="https://res.cloudinary.com/ddlupbcws/image/upload/v1711632600/people-office-work-day_1_iohjjo.webp"
                alt=""
                className="h-[100%] w-[100%] rounded-[20px] blur-[3px]"
              />
              <div className="absolute w-[100%] h-[100%] 2xl:pt-[70px] pt-[25px] 2xl:pl-[40px] lg:pl-[20px] flex flex-col items-center lg:items-start">
                <h1 className="text-white font-plus lg:text-[1.9rem] text-[1.6rem] font-extrabold mb-[24px] text-center lg:text-start 2xl:w-[80%]">
                  Your opinion matters! Share your thoughts in the survey.
                </h1>
                {!params.get("trader_email") && (
                  <a
                    href="https://survey.zohopublic.com/zs/9mDH5Y"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="bg-[#00C500] font-semibold px-[30px] py-[17px] rounded-[5px] text-white">
                      Take Survey
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateClan
        refresh={refresh}
        setRefresh={setRefresh}
        isOpen={open}
        setIsOpen={setOpen}
        showRequestSuccess={showRequestSuccess}
        showRequestFailed={showRequestFailed}
      />
      <JoinCompetition
        showRequestSuccess={showRequestSuccess}
        showRequestFailed={showRequestFailed}
        isOpen={joinOpen}
        setIsOpen={setJoinOpen}
        accounts={acc}
        clanIdAvailable={clanIdAvailable}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
}

export default Home;
